<template>
    <bread-page>
        <div class="main" v-loading="loading">
            <div class="title">{{ info.name }}</div>
            <div class="sub-title">
                <span>发布时间：{{ info.issuedAt }}</span>
                <span v-if="info.category === 'LAW'">实施时间：{{ info.implementationAt }}</span>
                <span>发文单位：{{ info.issuedBy }}</span>
            </div>
            <div class="content" v-html="info.content"></div>
        </div>
    </bread-page>
</template>

<script>
import BreadPage from '../../components/page/BreadPage.vue';
import { categoryOptions } from '../../utils/variables';
export default {
    components: { BreadPage },
    data() {
        return {
            loading: false,
            info: {}
        };
    },
    computed: {
        typeName() {
            return this.info.category === 'LAW' ? '法律详情' : '政策详情';
        }
    },
    mounted() {
        this.loading = true;
        this.$http.get('/policyLaw/get/' + this.$route.query.id).then(res => {
            this.info = res;
            setTimeout(() => {
                this.loading = false;
            }, 500);

            this.$nextTick(() => {
                this.$EventBus.$emit('changeTitle', this.typeName);
            });
            let info = categoryOptions.find(item => {
                return item.value === res.category;
            });
            this.$EventBus.$emit(
                'changePreTitle',
                res.category === 'LAW'
                    ? {
                          name: info.label,
                          path: '/legal'
                      }
                    : {
                          name: info.label,
                          path: '/policy'
                      }
            );
            this.$EventBus.$emit('changeTitle', info.label + '详情');
            document.title = info.label + '详情';
        });
    }
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    padding: 0 150px 60px;
    box-sizing: border-box;
    .min-height(606px);
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    line-height: 34px;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid @bg;
}
.sub-title {
    .flex();
    justify-content: center;
    font-size: 14px;
    color: #939599;
    line-height: 24px;
    span + span {
        margin-left: 40px;
    }
    padding: 20px 0 40px;
}

.content {
    font-size: 14px;
    color: #000000;
    line-height: 28px;

    /deep/ img {
        display: block;
        margin: 10px auto;
    }
    // p {
    // font-size: 14px;
    // color: #000000;
    // line-height: 28px;
    // }
}
</style>
